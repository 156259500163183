import React, { PureComponent } from 'react';
import { Field } from 'redux-form';


export default function field(Component) {
  return class ReduxField extends PureComponent {
    render() {
      return <Field {...this.props} component={Component} />;
    }
  };
}
